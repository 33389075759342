import { CartProductRef } from 'state/stores/checkout';
import type * as GqlSchemaWorker from '@svelte/service/backend/graphql/gql';
import type { PaymentIntent } from '@stripe/stripe-js';
import { SocialMediaChannel } from 'gatsby/graphql/useSocialMediaLogos';
import type { WindowLocation } from '@reach/router';
import { NotificationKind } from '@svelte/lib/notifications/types';

export const enum LoggedInMethod {
  EmailToken = 'email_token',
  DirectToken = 'direct_token',
  EmailConfirmation = 'email_confirmation',
  Discount = 'discount'
}

export const enum ReissueReason {
  Expired = 'expired',
  AlreadyUsed = 'already_used'
}

export enum AppEventName {
  // Navigation
  PageViewed = 'page_viewed',
  // User and sessions
  Authenticated = 'authenticated',
  EmailConfirmed = 'email_confirmed',
  MagicLinkRequested = 'magic_link_requested',
  MagicLinkUsed = 'magic_link_used',
  MagicLinkReissued = 'magic_link_reissued',
  MagicLinkFlowComplete = 'magic_link_flow_complete',
  LoggedIn = 'logged_in',
  LoggedInAnotherTab = 'logged_in_another_tab',
  LoggedOut = 'logged_out',
  ReLoggedIn = 're_logged_in',
  UserAcceptedPolicies = 'accepted_disruptor_policies',
  SawCarouselItem = 'saw_carousel_item',
  // Our Story
  StartedWatchingCompanyVideo = 'played_company_video',
  FinishedWatchingCompanyVideo = 'watched_company_video',
  SawBeautyIndustrySecrets = 'saw_beauty_industry_secrets_section',
  ReadAboutIndustrySecret = 'read_beauty_industry_secret',
  SawCompanyPillar = 'saw_company_pillar',
  SawAllCompanyPillars = 'saw_all_company_pillars',
  SawFoundersInfo = 'saw_founders_info',
  // Cart and Checkout
  CartHydrated = 'cart_hydrated',
  CartInitiated = 'cart_initiated',
  AddedToCart = 'added_cart_item',
  CartUpdated = 'cart_updated',
  RemovedFromCart = 'removed_cart_item',
  EditedCartItem = 'edited_cart_item',
  StartedCheckout = 'started_checkout',
  StripeFormLoaded = 'stripe_form_loaded',
  StripePaymentFailed = 'stripe_payment_failed',
  StripePaymentInProgress = 'stripe_payment_in_progress',
  PurchaseCompleted = 'completed_purchase',
  SetCartCustomerDetails = 'set_order_details',
  // Product
  SawHonestList = 'saw_honest_list',
  ReadFullProductDescription = 'read_full_product_description',
  SawProductHowTo = 'saw_how_to',
  SawProductReviews = 'saw_product_reviews',
  SawRelatedProducts = 'saw_related_products',
  // Home page
  SawAllUsp = 'saw_all_usp',
  SawMissionDriven = 'saw_mission_driven',
  SawProductCatHighlight = 'saw_cat_highlight',
  SawCompanyReviews = 'saw_company_reviews',
  // Support
  ChatwootLoaded = 'chat_loaded',
  CreatedTicket = 'created_support_ticket',
  OpenedChat = 'started_chat',
  ClosedChat = 'closed_chat',
  // Marketing
  SawNewsletterBanner = 'saw_newsletter_banner',
  NewsletterOfferPrompted = 'newsletter_subscription_prompted',
  NotificationDismissed = 'notification_dismissed',
  NotificationTimedOut = 'notification_timed_out',
  SubscribedNewsletter = 'newsletter_subscription_requested',
  ConfirmedNewsletter = 'newsletter_subscription_confirmed',
  ClickedSocialMedia = 'clicked_on_social_media_link',
  ClaimedDiscount = 'claimed_discount',
  CommsMenuMounted = 'comms_menu_mounted',
  CommsMenuUnmounted = 'comms_menu_unmounted'
}

export type AppEvents = {
  /**
   * Navigation
   */
  [AppEventName.PageViewed]: {
    location: WindowLocation;
  };
  /**
   * User and sessions
   */
  [AppEventName.Authenticated]: GqlSchemaWorker.SessionUserFragment;
  [AppEventName.EmailConfirmed]: void;
  [AppEventName.MagicLinkRequested]: { email: string };
  [AppEventName.MagicLinkUsed]: { result: GqlSchemaWorker.MagicLinkStatus };
  [AppEventName.MagicLinkReissued]: { reason: ReissueReason };
  [AppEventName.MagicLinkFlowComplete]: void;
  [AppEventName.LoggedIn]: {
    method: LoggedInMethod;
    sessionUser: GqlSchemaWorker.SessionUserFragment;
  };
  [AppEventName.LoggedInAnotherTab]: void;
  [AppEventName.LoggedOut]: GqlSchemaWorker.SessionUserFragment;
  [AppEventName.ReLoggedIn]: void;
  [AppEventName.UserAcceptedPolicies]: void;
  [AppEventName.SawCarouselItem]: {
    carouselName: string;
    index: number;
  };
  /**
   * Cart events
   */
  [AppEventName.CartHydrated]: void;
  [AppEventName.CartInitiated]: void;
  [AppEventName.CartUpdated]: void;
  [AppEventName.AddedToCart]: Omit<GqlSchemaWorker.CartProduct, 'skus'> & {
    sku: GqlSchemaWorker.CartSku;
  };
  [AppEventName.RemovedFromCart]: {
    productId: number;
    skuId: number;
  };
  [AppEventName.EditedCartItem]: CartProductRef;
  [AppEventName.StartedCheckout]: void;
  [AppEventName.SetCartCustomerDetails]: GqlSchemaWorker.CartCustomerDetails;
  [AppEventName.StripeFormLoaded]: void;
  [AppEventName.StripePaymentFailed]: {
    paymentIntent: PaymentIntent;
  };
  [AppEventName.StripePaymentInProgress]: void;
  [AppEventName.PurchaseCompleted]: void;
  /**
   * Our Story
   */
  [AppEventName.StartedWatchingCompanyVideo]: void;
  [AppEventName.FinishedWatchingCompanyVideo]: void;
  [AppEventName.SawBeautyIndustrySecrets]: void;
  [AppEventName.ReadAboutIndustrySecret]: { secretName: string };
  [AppEventName.SawCompanyPillar]: { name: string };
  [AppEventName.SawAllCompanyPillars]: void;
  [AppEventName.SawFoundersInfo]: void;
  /**
   * Product
   */
  [AppEventName.SawHonestList]: {
    productId: number;
  };
  [AppEventName.ReadFullProductDescription]: {
    productId: number;
  };
  [AppEventName.SawProductHowTo]: {
    productId: number;
  };
  [AppEventName.SawProductReviews]: {
    productId: number;
  };
  [AppEventName.SawRelatedProducts]: {
    productId: number;
  };
  /**
   * Home
   */
  [AppEventName.SawAllUsp]: void;
  [AppEventName.SawProductCatHighlight]: {
    name: string;
  };
  [AppEventName.SawMissionDriven]: void;
  [AppEventName.SawCompanyReviews]: void;
  /**
   * Support
   */
  [AppEventName.ChatwootLoaded]: void;
  [AppEventName.CreatedTicket]: { email: string };
  [AppEventName.OpenedChat]: void;
  [AppEventName.ClosedChat]: void;
  /**
   * Marketing
   */
  [AppEventName.ConfirmedNewsletter]: { email: string };
  [AppEventName.SubscribedNewsletter]: { email: string };
  [AppEventName.ClickedSocialMedia]: {
    socialMediaChannel: SocialMediaChannel;
  };
  [AppEventName.ClaimedDiscount]: {
    cart: GqlSchemaWorker.CartRemoteFragment;
    email: string;
  };
  [AppEventName.SawNewsletterBanner]: void;
  [AppEventName.NewsletterOfferPrompted]: { discount: number };
  [AppEventName.NotificationDismissed]: { kind: NotificationKind; id: string };
  [AppEventName.NotificationTimedOut]: { kind: NotificationKind; id: string };
  [AppEventName.CommsMenuMounted]: { device: CommsMenuDevice };
  [AppEventName.CommsMenuUnmounted]: { device: CommsMenuDevice };
};

export type CommsMenuDevice = 'desktop' | 'mobile';
export type AppEventPayload<T extends keyof AppEvents> = AppEvents[T];
export type AppEvent<Name extends keyof AppEvents> =
  AppEventPayload<Name> extends void
    ? {
        name: Name;
        payload?: void;
      }
    : {
        name: Name;
        payload: AppEventPayload<Name>;
      };
