import * as Sentry from '@sentry/browser';

import {
  AppEvent,
  AppEventName,
  AppEventPayload
} from '../../lib/events/contracts';

import type * as GqlSchemaWorker from '@svelte/service/backend/graphql/gql';
import type PostHog from '@j3a/posthog-js';
import isbot from 'isbot';
import toTitleCase from 'titlecase';

function isBot() {
  return isbot(navigator.userAgent);
}

const environment =
  process.env.GATSBY_SENTRY_ENVIRONMENT ||
  process.env.CF_PAGES_BRANCH ||
  'production';

Sentry.init({
  dsn: process.env.GATSBY_SENTRY_DSN,
  tunnel: process.env.GATSBY_SENTRY_TUNNEL,
  enabled: process.env.GATSBY_SENTRY_DISABLED !== 'true',
  environment,
  release: process.env.CF_PAGES_COMMIT_SHA,
  async beforeSend(event, _hint) {
    if (isBot()) {
      return null;
    }

    return event;
  }
});

// Shim for posthog script
let mainLocation: ShimmedLocation | undefined;

export type SendBeaconPayload = {
  type: 'beaconPayload';
  beaconPayload: Parameters<Window['navigator']['sendBeacon']>;
};

// export type WorkerErrorPayload = {
//   type: 'workerError';
//   message: WorkerError;
// };

export type PosthogWorkerMessage = SendBeaconPayload;
// export type PosthogWorkerMessage = WorkerErrorPayload | SendBeaconPayload;

// function sendError(data: Omit<WorkerError, 'workerName' | 'handled'>) {
//   self.postMessage({
//     type: 'workerError',
//     message: {
//       ...data,
//       workerName: 'PostHog',
//       handled: true
//     }
//   } as WorkerErrorPayload);
// }

type MakeWindowProxyProps = {
  navigatorProxy?: Navigator;
  mainDocument: unknown;
  screen: Screen;
};

const makeWindowProxy = ({
  navigatorProxy,
  mainDocument,
  screen
}: MakeWindowProxyProps): typeof self => {
  return new Proxy(self, {
    get(target, prop) {
      if (prop === 'location') {
        return mainLocation;
      }

      if (prop === 'document') {
        return mainDocument;
      }

      if (prop === 'navigator') {
        return navigatorProxy || self.navigator;
      }

      if (prop === 'screen') {
        return screen;
      }

      // @ts-expect-error shim
      return typeof target[prop] === 'function'
        ? // @ts-expect-error shim
          target[prop].bind(target)
        : // @ts-expect-error shim
          target[prop];
    },
    has(target, key) {
      if (['screen', 'document', 'location'].some(s => s === key)) {
        return true;
      }

      return key in target;
    }
  });
};

const makeNavigationProxy = (
  beaconSupported: boolean
): Navigator | undefined => {
  if (!beaconSupported) {
    return undefined;
  }

  const sendBeacon: Window['navigator']['sendBeacon'] = (...args) => {
    postMessage({
      type: 'beaconPayload',
      beaconPayload: args
    } as SendBeaconPayload);
    return true;
  };

  return new Proxy(self.navigator, {
    get(target, prop) {
      if (prop === 'sendBeacon') {
        return sendBeacon;
      }

      // @ts-expect-error shim
      return typeof target[prop] === 'function'
        ? // @ts-expect-error shim
          target[prop].bind(target)
        : // @ts-expect-error shim
          target[prop];
    },
    has(target, key) {
      if (key === 'sendBeacon') {
        return true;
      }
      return key in target;
    }
  });
};

let posthog: typeof PostHog | null = null;
const preloadBuffer: Array<AppEvent<AppEventName>> = [];

const API_KEY = process.env.GATSBY_POSTHOG_API_KEY as string;
const HOST = process.env.GATSBY_POSTHOG_HOST as string;
const DEBUG = false;
const IS_DISABLED = isBot() || process.env.GATSBY_POSTHOG_DISABLED === 'true';

export type ShimmedLocation = Omit<
  Location,
  'assign' | 'reload' | 'replace' | 'ancestorOrigins'
> & {
  ancestorOrigins: string[];
};

export type WindowShim = {
  name: 'window-shim';
  location: ShimmedLocation;
  referrer: string;
  screen: Screen;
  sendBeaconSupported: boolean;
};

self.onmessage = ({
  data
}: MessageEvent<AppEvent<AppEventName> | WindowShim>) => {
  if (IS_DISABLED || !API_KEY || !HOST) {
    return;
  }

  if (data.name === 'window-shim') {
    return patchWindow(data);
  }

  handleEvent(data);
};

function parseUser(user: Partial<GqlSchemaWorker.UserProfileFragment>) {
  const { role, ...rest } = user;
  return {
    ...rest,
    fullName: [user.firstName, user.surname1, user.surname2]
      .filter(Boolean)
      .join(' '),
    role: role ? toTitleCase(role.toLowerCase()) : undefined
  };
}

function patchWindow(event: WindowShim) {
  const mainDocument = {
    body: {} as unknown,
    referrer: event.referrer
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  globalThis.document = mainDocument as any;
  mainLocation = event.location;
  globalThis.window = makeWindowProxy({
    navigatorProxy: makeNavigationProxy(event.sendBeaconSupported),
    mainDocument,
    screen: event.screen
  });
}

function handleEvent(event: AppEvent<AppEventName>) {
  Sentry.addBreadcrumb({
    type: 'info',
    level: 'info',
    message: 'Received AppEvent',
    data: {
      ...event
    }
  });

  const { name, payload } = event;
  if (!name) {
    Sentry.captureException('Invalid analytics event');
    return;
  }

  if (name === AppEventName.Authenticated) {
    handleSessionBootstrapped(
      payload as AppEventPayload<AppEventName.Authenticated>
    );
    return;
  }

  if (!posthog) {
    if (preloadBuffer.length < 5000) {
      preloadBuffer.push(event);
    } else {
      Sentry.setContext('Preload buffer', {
        length: preloadBuffer.length,
        sample: preloadBuffer.slice(0, 11).map(x => JSON.stringify(x))
      });
      Sentry.captureException('Posthog buffer is full. Unable to init posthog');
    }

    return;
  }

  switch (name) {
    case AppEventName.PageViewed:
      const p = payload as AppEventPayload<AppEventName.PageViewed>;
      mainLocation = {
        ...p.location,
        ancestorOrigins: []
      };
      posthog.capture('$pageview');
      break;
    case AppEventName.LoggedOut:
    case AppEventName.LoggedIn:
      handleUserChanged(
        payload as GqlSchemaWorker.SessionUserFragment,
        posthog
      );
      break;
    case AppEventName.SetCartCustomerDetails:
      handleCartCustomerDetailsChange(
        payload as AppEventPayload<AppEventName.SetCartCustomerDetails>,
        posthog
      );
      break;
    case AppEventName.SubscribedNewsletter:
    case AppEventName.ConfirmedNewsletter:
    case AppEventName.ClaimedDiscount:
    case AppEventName.CreatedTicket:
      handleEventWithEmail(name, payload as { email: string }, posthog);
      break;
    default:
      posthog.capture(name, payload || undefined);
  }
}

function handleSessionBootstrapped(
  sessionUser: GqlSchemaWorker.SessionUserFragment
) {
  const { id: uid, profile: user } = sessionUser;
  const setData: Record<string, unknown> = user ? parseUser(user) : {};

  if (!self.window.location) {
    throw Error('Main location should be shimmed before init');
  }

  if (!posthog) {
    /**
     * Initiate PostHog
     */

    const queryParams = new URLSearchParams(self.window.location.search);
    const gclid = queryParams.get('gclid');
    const wbraid = queryParams.get('wbraid');
    const gbraid = queryParams.get('gbraid');

    const setDataOnce: Record<string, string> = {};

    /**
     * Posthog adds gclid but others not
     * Add new iOS tracking ids
     * as per https://github.com/PostHog/posthog/issues/10860
     */
    if (!gclid) {
      if (wbraid) {
        setData.wbraid = wbraid;
        setDataOnce['$initial_wbraid'] = wbraid;
      }
      if (gbraid) {
        setData.gbraid = gbraid;
        setDataOnce['$initial_gbraid'] = gbraid;
      }
    }

    // NOTE: posthog is imported dynamically to allow for location and window to be shimmed
    import(/* webpackChunkName: "perc-client" */ '@j3a/posthog-js').then(ph => {
      posthog = ph.posthog.init(API_KEY, {
        autocapture: false,
        api_host: HOST,
        capture_pageview: false,
        capture_performance: false,
        disable_persistence: true,
        loaded: function (posthog) {
          posthog.identify(uid, setData, setDataOnce);
        },
        on_xhr_error: request => {
          Sentry.setContext('XHR request', {
            message: `Bad HTTP status: ${request.status} ${request.statusText}`,
            response: request.responseText
          });
          Sentry.captureException('Unable to send posthog event using XHR');
        },
        session_recording: {
          maskAllInputs: !user,
          maskInputOptions: {
            password: true
          }
        },
        api_transport:
          'sendBeacon' in window.navigator ? 'sendBeacon' : undefined
      }) as typeof PostHog;

      DEBUG && posthog.debug();

      for (const event of preloadBuffer) {
        handleEvent(event);
      }
    });
  }
  prevSessionData = sessionUser;
}

let prevSessionData: undefined | GqlSchemaWorker.SessionUserFragment;
function handleUserChanged(
  nextSession: GqlSchemaWorker.SessionUserFragment,
  posthog: typeof PostHog
) {
  const { profile: user } = nextSession;
  const setData: Record<string, unknown> = user ? parseUser(user) : {};

  /**
   * Handle user change
   */
  const nextUid = nextSession.id;
  const prevUid = prevSessionData?.id;
  const nextUser = nextSession.profile;
  const prevUser = prevSessionData?.profile;
  const hasLoggedIn = !prevUser && nextUser;
  const hasLoggedOut = !!prevUser && !nextUser;

  if (hasLoggedIn) {
    Sentry.addBreadcrumb({
      type: 'user',
      message: 'Logged in',
      level: 'info',
      data: {
        nextSession,
        prevSessionData
      }
    });
    posthog.alias(nextUid, prevUid);
    posthog.capture('logged_in', {
      $set: setData
    });
  } else if (hasLoggedOut) {
    Sentry.addBreadcrumb({
      type: 'user',
      message: 'Logged out',
      level: 'info',
      data: {
        nextSession,
        prevSessionData
      }
    });
    posthog.capture('logged_out');
    posthog.reset();
    posthog.identify(nextUid, setData);
  } else if (nextUid != prevUid) {
    Sentry.addBreadcrumb({
      type: 'user',
      message: 'Session user changed',
      level: 'info',
      data: {
        nextSession,
        prevSessionData
      }
    });
    Sentry.captureException(
      'Impersonation is not supported but it seems one user replaced another'
    );
    posthog.capture('user_impersonated', {
      nextUser,
      prevUser
    });
  }

  prevSessionData = nextSession;
}

function handleCartCustomerDetailsChange(
  details: AppEventPayload<AppEventName.SetCartCustomerDetails>,
  posthog: typeof PostHog
) {
  const firstName = (details.billingAddress || details.shippingAddress)?.name;
  const lastName = (details.billingAddress || details.shippingAddress)
    ?.surname1;
  const surname2 = (details.billingAddress || details.shippingAddress)
    ?.surname2;
  const fullName = [firstName, lastName, surname2].join(' ');
  posthog.capture(AppEventName.SetCartCustomerDetails, {
    email: details.email,
    fullName,
    $set: {
      email: details.email,
      fullName
    }
  });
}
function handleEventWithEmail(
  name: string,
  payload: { email: string } & Record<string, unknown>,
  posthog: typeof PostHog
) {
  posthog.capture(name, {
    ...payload,
    $set: {
      email: payload.email
    }
  });
}
